.o-navMain{
    background: $bg-primary;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 76px;
    z-index: 5;
    position: relative;
}
@media screen and (max-width:991px){
    .o-navMain{
        height: 56px;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 6;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    }
}
