.a-icon{
    display: block;
    height: 20px;
    width: 20px;
    background-repeat: no-repeat;
    background-position: center center;
}

.a-icon.-hamburger{
    height: 2px;
    width: 18px;
    background: $bg-primary;
    display: block;
    @include center(both);
    position: relative;
    transition: 0.3s all ease;

    &::before, &::after{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: $bg-primary;
        transform: translate(0%,-300%);
        transition: 0.3s all ease; 
    }
    &::after{
        transform: translate(0%,300%);     
    }

    &.-white{
        background: $bg-white;
        
        &::before, &::after{
            background: $bg-white;
        }
    }
}



.a-icon.-cheveron{
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3LjIwNyAxMiI+PGRlZnM+PHN0eWxlPi5he2ZpbGw6IzI0MjcyZDt9PC9zdHlsZT48L2RlZnM+PHBhdGggY2xhc3M9ImEiIGQ9Ik05LjM0NSw5LjA1OWExLjIyNCwxLjIyNCwwLDAsMSwxLjcxNC0xLjcxNGw0LjksNC45YTEuMjI0LDEuMjI0LDAsMCwxLDAsMS43MTRsLTQuOSw0LjlhMS4yMjQsMS4yMjQsMCwwLDEtMS43MTQtMS43MTRMMTMuMzczLDEzLjEsOS4zMzMsOS4wNTlaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtOS4xIC03LjEpIi8+PC9zdmc+"); 
    
    &.-white{
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3LjIwNyAxMiI+PGRlZnM+PHN0eWxlPi5he2ZpbGw6I2ZmZjt9PC9zdHlsZT48L2RlZnM+PHBhdGggY2xhc3M9ImEiIGQ9Ik05LjM0NSw5LjA1OWExLjIyNCwxLjIyNCwwLDAsMSwxLjcxNC0xLjcxNGw0LjksNC45YTEuMjI0LDEuMjI0LDAsMCwxLDAsMS43MTRsLTQuOSw0LjlhMS4yMjQsMS4yMjQsMCwwLDEtMS43MTQtMS43MTRMMTMuMzczLDEzLjEsOS4zMzMsOS4wNTlaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtOS4xIC03LjEpIi8+PC9zdmc+"); 
    }
}

.a-icon.-user{
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxOS44IDIyIj48ZGVmcz48c3R5bGU+LmF7ZmlsbDojMjQyNzJkO308L3N0eWxlPjwvZGVmcz48cGF0aCBjbGFzcz0iYSIgZD0iTTMsMjIuOUExLjEsMS4xLDAsMCwwLDQuMSwyNEgyMS43YTEuMSwxLjEsMCwwLDAsMS4xLTEuMVYyMS44YTkuOTE0LDkuOTE0LDAsMCwwLTYuMDI2LTkuMTA5LDYuMDUsNi4wNSwwLDEsMC03Ljc0OCwwQTkuOTE1LDkuOTE1LDAsMCwwLDMsMjEuOFpNOS4wNSw4LjA1QTMuODUsMy44NSwwLDEsMSwxMi45LDExLjksMy44NTQsMy44NTQsMCwwLDEsOS4wNSw4LjA1Wk0xMi45LDE0LjFhNy43MSw3LjcxLDAsMCwxLDcuNyw3LjdINS4yQTcuNzA5LDcuNzA5LDAsMCwxLDEyLjksMTQuMVoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0zIC0yKSIvPjwvc3ZnPg=="); 
    
    &.-white{
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxOS44IDIyIj48ZGVmcz48c3R5bGU+LmF7ZmlsbDojZmZmO308L3N0eWxlPjwvZGVmcz48cGF0aCBjbGFzcz0iYSIgZD0iTTMsMjIuOUExLjEsMS4xLDAsMCwwLDQuMSwyNEgyMS43YTEuMSwxLjEsMCwwLDAsMS4xLTEuMVYyMS44YTkuOTE0LDkuOTE0LDAsMCwwLTYuMDI2LTkuMTA5LDYuMDUsNi4wNSwwLDEsMC03Ljc0OCwwQTkuOTE1LDkuOTE1LDAsMCwwLDMsMjEuOFpNOS4wNSw4LjA1QTMuODUsMy44NSwwLDEsMSwxMi45LDExLjksMy44NTQsMy44NTQsMCwwLDEsOS4wNSw4LjA1Wk0xMi45LDE0LjFhNy43MSw3LjcxLDAsMCwxLDcuNyw3LjdINS4yQTcuNzA5LDcuNzA5LDAsMCwxLDEyLjksMTQuMVoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0zIC0yKSIvPjwvc3ZnPg=="); 
    }
}

.a-icon.-cart{
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNC40NDUgMjIiPjxkZWZzPjxzdHlsZT4uYXtmaWxsOiMyNDI3MmQ7fTwvc3R5bGU+PC9kZWZzPjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEyNyAtMzMpIj48cGF0aCBjbGFzcz0iYSIgZD0iTTI2LjIsNC40ODVBMS4yMjgsMS4yMjgsMCwwLDAsMjUuMjIyLDRIMlY2LjQ0NEg0LjgxMWw0LjYsMTIuNjRhMS4yMiwxLjIyLDAsMCwwLDEuMTQ4LjhoMTFWMTcuNDQ0SDExLjQxMUwxMC41MjEsMTVIMjIuNzc4YTEuMjI0LDEuMjI0LDAsMCwwLDEuMTc2LS44ODdMMjYuNCw1LjU1N0ExLjIyOCwxLjIyOCwwLDAsMCwyNi4yLDQuNDg1Wm0tNC4zNDMsOC4wN0g5LjYzNEw3LjQxMiw2LjQ0NEgyMy42WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTEyOSAyOSkiLz48Y2lyY2xlIGNsYXNzPSJhIiBjeD0iMS44MzMiIGN5PSIxLjgzMyIgcj0iMS44MzMiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xMTguNDQ0IDUxLjMzMykiLz48Y2lyY2xlIGNsYXNzPSJhIiBjeD0iMS44MzMiIGN5PSIxLjgzMyIgcj0iMS44MzMiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xMTEuMTExIDUxLjMzMykiLz48L2c+PC9zdmc+"); 
    
    &.-white{
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNC40NDUgMjIiPjxkZWZzPjxzdHlsZT4uYXtmaWxsOiNmZmY7fTwvc3R5bGU+PC9kZWZzPjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEyNyAtMzMpIj48cGF0aCBjbGFzcz0iYSIgZD0iTTI2LjIsNC40ODVBMS4yMjgsMS4yMjgsMCwwLDAsMjUuMjIyLDRIMlY2LjQ0NEg0LjgxMWw0LjYsMTIuNjRhMS4yMiwxLjIyLDAsMCwwLDEuMTQ4LjhoMTFWMTcuNDQ0SDExLjQxMUwxMC41MjEsMTVIMjIuNzc4YTEuMjI0LDEuMjI0LDAsMCwwLDEuMTc2LS44ODdMMjYuNCw1LjU1N0ExLjIyOCwxLjIyOCwwLDAsMCwyNi4yLDQuNDg1Wm0tNC4zNDMsOC4wN0g5LjYzNEw3LjQxMiw2LjQ0NEgyMy42WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTEyOSAyOSkiLz48Y2lyY2xlIGNsYXNzPSJhIiBjeD0iMS44MzMiIGN5PSIxLjgzMyIgcj0iMS44MzMiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xMTguNDQ0IDUxLjMzMykiLz48Y2lyY2xlIGNsYXNzPSJhIiBjeD0iMS44MzMiIGN5PSIxLjgzMyIgcj0iMS44MzMiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xMTEuMTExIDUxLjMzMykiLz48L2c+PC9zdmc+"); 
    }
}

.a-icon.-search{
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNy45OTkgMTgiPjxkZWZzPjxzdHlsZT4uYXtmaWxsOiMyNDI3MmQ7fTwvc3R5bGU+PC9kZWZzPjxwYXRoIGNsYXNzPSJhIiBkPSJNOS43LDE3LjM5NGE3LjY1LDcuNjUsMCwwLDAsNC43MS0xLjYyM0wxOC42MzksMjAsMjAsMTguNjRsLTQuMjMtNC4yM0E3LjY4OCw3LjY4OCwwLDEsMCw5LjcsMTcuMzk0Wm0wLTEzLjQ3QTUuNzczLDUuNzczLDAsMSwxLDMuOTI0LDkuNyw1Ljc3OSw1Ljc3OSwwLDAsMSw5LjcsMy45MjRaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMiAtMikiLz48L3N2Zz4="); 
    
    &.-white{
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNy45OTkgMTgiPjxkZWZzPjxzdHlsZT4uYXtmaWxsOiNmZmY7fTwvc3R5bGU+PC9kZWZzPjxwYXRoIGNsYXNzPSJhIiBkPSJNOS43LDE3LjM5NGE3LjY1LDcuNjUsMCwwLDAsNC43MS0xLjYyM0wxOC42MzksMjAsMjAsMTguNjRsLTQuMjMtNC4yM0E3LjY4OCw3LjY4OCwwLDEsMCw5LjcsMTcuMzk0Wm0wLTEzLjQ3QTUuNzczLDUuNzczLDAsMSwxLDMuOTI0LDkuNyw1Ljc3OSw1Ljc3OSwwLDAsMSw5LjcsMy45MjRaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMiAtMikiLz48L3N2Zz4="); 
    }
}

.a-icon.-home{
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMC4wMDYgMzAiPjxkZWZzPjxzdHlsZT4uYXtmaWxsOiMyNDI3MmQ7fTwvc3R5bGU+PC9kZWZzPjxwYXRoIGNsYXNzPSJhIiBkPSJNMTguNTMxLDI5VjIxLjVoLTNWMjlhMywzLDAsMCwxLTMsM2gtNC41YTMsMywwLDAsMS0zLTNWMTcuNjE2bC0uNDUuNDVhMS41LDEuNSwwLDEsMS0yLjEtMi4xM2wxMy41LTEzLjVhMS41LDEuNSwwLDAsMSwyLjEsMGwxMy41LDEzLjVhMS41LDEuNSwwLDAsMS0yLjEsMi4xM2wtLjQ1LS40NVYyOWEzLDMsMCwwLDEtMywzaC00LjVhMywzLDAsMCwxLTMtM1ptNy41LDBWMTQuNjE2bC05LTktOSw5VjI5aDQuNVYyMS41YTMuMDA5LDMuMDA5LDAsMCwxLDMtM2gzYTMsMywwLDAsMSwzLDNWMjlaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMS45NDUgLTIuMDA0KSIvPjwvc3ZnPg=="); 
    
    &.-white{
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMC4wMDYgMzAiPjxkZWZzPjxzdHlsZT4uYXtmaWxsOiNmZmY7fTwvc3R5bGU+PC9kZWZzPjxwYXRoIGNsYXNzPSJhIiBkPSJNMTguNTMxLDI5VjIxLjVoLTNWMjlhMywzLDAsMCwxLTMsM2gtNC41YTMsMywwLDAsMS0zLTNWMTcuNjE2bC0uNDUuNDVhMS41LDEuNSwwLDEsMS0yLjEtMi4xM2wxMy41LTEzLjVhMS41LDEuNSwwLDAsMSwyLjEsMGwxMy41LDEzLjVhMS41LDEuNSwwLDAsMS0yLjEsMi4xM2wtLjQ1LS40NVYyOWEzLDMsMCwwLDEtMywzaC00LjVhMywzLDAsMCwxLTMtM1ptNy41LDBWMTQuNjE2bC05LTktOSw5VjI5aDQuNVYyMS41YTMuMDA5LDMuMDA5LDAsMCwxLDMtM2gzYTMsMywwLDAsMSwzLDNWMjlaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMS45NDUgLTIuMDA0KSIvPjwvc3ZnPg=="); 
    }
}

.a-icon.-address{
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMCAyNyI+PGRlZnM+PHN0eWxlPi5he2ZpbGw6IzI0MjcyZDt9PC9zdHlsZT48L2RlZnM+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTIgLTMpIj48cGF0aCBjbGFzcz0iYSIgZD0iTTYsN0gyNHYzSDZabTAsNkgxOS40ODV2M0g2Wm0wLDZoOXYzSDZaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyIDIpIi8+PHBhdGggY2xhc3M9ImEiIGQ9Ik0zMC41LDNIMy41QTEuNSwxLjUsMCwwLDAsMiw0LjV2MjRBMS41LDEuNSwwLDAsMCwzLjUsMzBoMjdBMS41LDEuNSwwLDAsMCwzMiwyOC41VjQuNUExLjUsMS41LDAsMCwwLDMwLjUsM1pNMjksMjdINVY2SDI5WiIvPjwvZz48L3N2Zz4="); 
    
    &.-white{
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMCAyNyI+PGRlZnM+PHN0eWxlPi5he2ZpbGw6I2ZmZjt9PC9zdHlsZT48L2RlZnM+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTIgLTMpIj48cGF0aCBjbGFzcz0iYSIgZD0iTTYsN0gyNHYzSDZabTAsNkgxOS40ODV2M0g2Wm0wLDZoOXYzSDZaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyIDIpIi8+PHBhdGggY2xhc3M9ImEiIGQ9Ik0zMC41LDNIMy41QTEuNSwxLjUsMCwwLDAsMiw0LjV2MjRBMS41LDEuNSwwLDAsMCwzLjUsMzBoMjdBMS41LDEuNSwwLDAsMCwzMiwyOC41VjQuNUExLjUsMS41LDAsMCwwLDMwLjUsM1pNMjksMjdINVY2SDI5WiIvPjwvZz48L3N2Zz4="); 
    }
}


.a-icon.-star{
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNi4wMzcgMTYiPjxkZWZzPjxzdHlsZT4uYXtmaWxsOiNmZmIzMDA7fTwvc3R5bGU+PC9kZWZzPjxwYXRoIGNsYXNzPSJhIiBkPSJNNS44OTMsMTEuOTI0bC0xLjQ1Nyw1LjFhLjguOCwwLDAsMCwxLjIxNi44ODdMMTAuMDE4LDE1bDQuMzY2LDIuOTExYS44LjgsMCwwLDAsMS4yMTYtLjg4N2wtMS40NTctNS4xLDMuNTkyLTIuODc0YS44LjgsMCwwLDAtLjM3LTEuNDE3TDEyLjk1OCw2LjksMTAuNzM2LDIuNDUzYS44MzUuODM1LDAsMCwwLTEuNDM0LDBMNy4wNzgsNi45LDIuNjcsNy42MzNBLjguOCwwLDAsMCwyLjMsOS4wNVoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0yIC0yLjA0NikiLz48L3N2Zz4="); 
    
    &.-empty{
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNi4wMzUgMTYiPjxkZWZzPjxzdHlsZT4uYXtmaWxsOiNmZmIzMDA7fTwvc3R5bGU+PC9kZWZzPjxwYXRoIGNsYXNzPSJhIiBkPSJNNS44OTMsMTEuOTIzbC0xLjQ1Nyw1LjFhLjguOCwwLDAsMCwxLjIxNi44ODdMMTAuMDE4LDE1bDQuMzY2LDIuOTExYS44LjgsMCwwLDAsMS4yMTYtLjg4N2wtMS40NTctNS4xTDE3LjczNSw5LjA1YS44LjgsMCwwLDAtLjM3LTEuNDE3TDEyLjk1OCw2LjksMTAuNzM2LDIuNDUzYS44MzUuODM1LDAsMCwwLTEuNDM0LDBMNy4wNzgsNi45LDIuNjcsNy42MzNBLjguOCwwLDAsMCwyLjMsOS4wNVpNNy43NDQsOC40MTJhLjguOCwwLDAsMCwuNTg1LS40MzJMMTAuMDE4LDQuNiwxMS43MDYsNy45OGEuOC44LDAsMCwwLC41ODUuNDMybDMuMDM5LjUwN0wxMi43MjMsMTFhLjguOCwwLDAsMC0uMjcuODQ3bDEsMy41MTYtMy0yYS44LjgsMCwwLDAtLjg4OSwwbC0zLDIsMS0zLjUxNkEuOC44LDAsMCwwLDcuMzEyLDExTDQuNzA1LDguOTE4bDMuMDM5LS41MDZaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMiAtMi4wNDYpIi8+PC9zdmc+"); 
    }
}

.a-icon.-checkCircle{
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxOCAxOCI+PGRlZnM+PHN0eWxlPi5he2ZpbGw6IzI0MjcyZDt9PC9zdHlsZT48L2RlZnM+PHBhdGggY2xhc3M9ImEiIGQ9Ik0xMSwyMGE5LDksMCwxLDEsOS05QTksOSwwLDAsMSwxMSwyMFptMC0xLjhBNy4yLDcuMiwwLDEsMCwzLjgsMTEsNy4yLDcuMiwwLDAsMCwxMSwxOC4yWk04LjkzLDEwLjM3bDEuMTcsMS4xNjEsMi45Ny0yLjk3YS45LjksMCwwLDEsMS4yNiwxLjI3OGwtMy42LDMuNmEuOS45LDAsMCwxLTEuMjYsMGwtMS44LTEuOGEuOS45LDAsMCwxLDEuMjYtMS4yNzhaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMiAtMikiLz48L3N2Zz4="); 
    
    &.-green{
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxOCAxOCI+PGRlZnM+PHN0eWxlPi5he2ZpbGw6IzM0YWQ3Yzt9PC9zdHlsZT48L2RlZnM+PHBhdGggY2xhc3M9ImEiIGQ9Ik0xMSwyMGE5LDksMCwxLDEsOS05QTksOSwwLDAsMSwxMSwyMFptMC0xLjhBNy4yLDcuMiwwLDEsMCwzLjgsMTEsNy4yLDcuMiwwLDAsMCwxMSwxOC4yWk04LjkzLDEwLjM3bDEuMTcsMS4xNjEsMi45Ny0yLjk3YS45LjksMCwwLDEsMS4yNiwxLjI3OGwtMy42LDMuNmEuOS45LDAsMCwxLTEuMjYsMGwtMS44LTEuOGEuOS45LDAsMCwxLDEuMjYtMS4yNzhaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMiAtMikiLz48L3N2Zz4="); 
    }
}

.a-icon.-envelope{
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNy41IDE0Ij48ZGVmcz48c3R5bGU+LmF7ZmlsbDojMjQyNzJkO308L3N0eWxlPjwvZGVmcz48cGF0aCBjbGFzcz0iYSIgZD0iTTMuNzUsNGgxNEExLjc1LDEuNzUsMCwwLDEsMTkuNSw1Ljc1djEwLjVBMS43NSwxLjc1LDAsMCwxLDE3Ljc1LDE4aC0xNEExLjc1LDEuNzUsMCwwLDEsMiwxNi4yNVY1Ljc1QTEuNzU1LDEuNzU1LDAsMCwxLDMuNzUsNFptMTQsMi45NThWNS43NWgtMTRWNi45NThsNywzLjVabTAsMS45Ni02LjYwNiwzLjNhLjg3NS44NzUsMCwwLDEtLjc4OCwwTDMuNzUsOC45MTdWMTYuMjVoMTRaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMiAtNCkiLz48L3N2Zz4="); 
    
    &.-white{
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNy41IDE0Ij48ZGVmcz48c3R5bGU+LmF7ZmlsbDojZmZmO308L3N0eWxlPjwvZGVmcz48cGF0aCBjbGFzcz0iYSIgZD0iTTMuNzUsNGgxNEExLjc1LDEuNzUsMCwwLDEsMTkuNSw1Ljc1djEwLjVBMS43NSwxLjc1LDAsMCwxLDE3Ljc1LDE4aC0xNEExLjc1LDEuNzUsMCwwLDEsMiwxNi4yNVY1Ljc1QTEuNzU1LDEuNzU1LDAsMCwxLDMuNzUsNFptMTQsMi45NThWNS43NWgtMTRWNi45NThsNywzLjVabTAsMS45Ni02LjYwNiwzLjNhLjg3NS44NzUsMCwwLDEtLjc4OCwwTDMuNzUsOC45MTdWMTYuMjVoMTRaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMiAtNCkiLz48L3N2Zz4="); 
    }
}

.a-icon.-phone{
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGRlZnM+PHN0eWxlPi5he2ZpbGw6IzI0MjcyZDt9PC9zdHlsZT48L2RlZnM+PHBhdGggY2xhc3M9ImEiIGQ9Ik0xNS4yNDgsMTcuMjI4bDEuMjg0LTIuNTY4YTEuMiwxLjIsMCwwLDEsMS40NC0uNmw3LjIsMi40QTEuMiwxLjIsMCwwLDEsMjYsMTcuNnY2QTIuNCwyLjQsMCwwLDEsMjMuNiwyNkgyMS4yQTE5LjIsMTkuMiwwLDAsMSwyLDYuOFY0LjRBMi40MDcsMi40MDcsMCwwLDEsNC40LDJoNmExLjIsMS4yLDAsMCwxLDEuMTQuODE2bDIuNCw3LjJhMS4yLDEuMiwwLDAsMS0uNiwxLjQ1MmwtMi41OCwxLjI4NGExMi4wNiwxMi4wNiwwLDAsMCw0LjQ3Niw0LjQ3NlpNOS41MzYsNC40SDQuNFY2LjhBMTYuOCwxNi44LDAsMCwwLDIxLjIsMjMuNmgyLjRWMTguNDY0bC01LjQtMS44LTEuMzQ0LDIuNzEyYTEuMiwxLjIsMCwwLDEtMS41Ni41NTIsMTQuNDQ4LDE0LjQ0OCwwLDAsMS03LjIyNC03LjIxMiwxLjIsMS4yLDAsMCwxLC41NTItMS41NmwyLjcxMi0xLjM2OFoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0yIC0yKSIvPjwvc3ZnPg=="); 
    
    &.-white{
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGRlZnM+PHN0eWxlPi5he2ZpbGw6I2ZmZjt9PC9zdHlsZT48L2RlZnM+PHBhdGggY2xhc3M9ImEiIGQ9Ik0xNS4yNDgsMTcuMjI4bDEuMjg0LTIuNTY4YTEuMiwxLjIsMCwwLDEsMS40NC0uNmw3LjIsMi40QTEuMiwxLjIsMCwwLDEsMjYsMTcuNnY2QTIuNCwyLjQsMCwwLDEsMjMuNiwyNkgyMS4yQTE5LjIsMTkuMiwwLDAsMSwyLDYuOFY0LjRBMi40MDcsMi40MDcsMCwwLDEsNC40LDJoNmExLjIsMS4yLDAsMCwxLDEuMTQuODE2bDIuNCw3LjJhMS4yLDEuMiwwLDAsMS0uNiwxLjQ1MmwtMi41OCwxLjI4NGExMi4wNiwxMi4wNiwwLDAsMCw0LjQ3Niw0LjQ3NlpNOS41MzYsNC40SDQuNFY2LjhBMTYuOCwxNi44LDAsMCwwLDIxLjIsMjMuNmgyLjRWMTguNDY0bC01LjQtMS44LTEuMzQ0LDIuNzEyYTEuMiwxLjIsMCwwLDEtMS41Ni41NTIsMTQuNDQ4LDE0LjQ0OCwwLDAsMS03LjIyNC03LjIxMiwxLjIsMS4yLDAsMCwxLC41NTItMS41NmwyLjcxMi0xLjM2OFoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0yIC0yKSIvPjwvc3ZnPg=="); 
    }
}

.a-icon.-personalInfo{
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNCAxMS4yIj48ZGVmcz48c3R5bGU+LmF7ZmlsbDojNGU1MDU1O308L3N0eWxlPjwvZGVmcz48cGF0aCBjbGFzcz0iYSIgZD0iTTIuNyw4LjlINi4yYS43LjcsMCwwLDAsLjctLjdWNC43QS43LjcsMCwwLDAsNi4yLDRIMi43YS43LjcsMCwwLDAtLjcuN1Y4LjJBLjcuNywwLDAsMCwyLjcsOC45Wm0uNy0zLjVINS41VjcuNUgzLjRabS0uNyw5LjhINi4yYS43LjcsMCwwLDAsLjctLjdWMTFhLjcuNywwLDAsMC0uNy0uN0gyLjdBLjcuNywwLDAsMCwyLDExdjMuNUEuNy43LDAsMCwwLDIuNywxNS4yWm0uNy0zLjVINS41djIuMUgzLjRaTTguMyw0SDE2VjUuNEg4LjNabTAsMi44aDYuM1Y4LjJIOC4zWm0wLDMuNUgxNnYxLjRIOC4zWm0wLDIuOGg2LjN2MS40SDguM1oiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0yIC00KSIvPjwvc3ZnPg=="); 
    
    &.-red{
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNCAxMS4yIj48ZGVmcz48c3R5bGU+LmF7ZmlsbDojYzQxZTNkO308L3N0eWxlPjwvZGVmcz48cGF0aCBjbGFzcz0iYSIgZD0iTTIuNyw4LjlINi4yYS43LjcsMCwwLDAsLjctLjdWNC43QS43LjcsMCwwLDAsNi4yLDRIMi43YS43LjcsMCwwLDAtLjcuN1Y4LjJBLjcuNywwLDAsMCwyLjcsOC45Wm0uNy0zLjVINS41VjcuNUgzLjRabS0uNyw5LjhINi4yYS43LjcsMCwwLDAsLjctLjdWMTFhLjcuNywwLDAsMC0uNy0uN0gyLjdBLjcuNywwLDAsMCwyLDExdjMuNUEuNy43LDAsMCwwLDIuNywxNS4yWm0uNy0zLjVINS41djIuMUgzLjRaTTguMyw0SDE2VjUuNEg4LjNabTAsMi44aDYuM1Y4LjJIOC4zWm0wLDMuNUgxNnYxLjRIOC4zWm0wLDIuOGg2LjN2MS40SDguM1oiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0yIC00KSIvPjwvc3ZnPg=="); 
    }
}

.a-icon.-calendar{
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNCAxNCI+PGRlZnM+PHN0eWxlPi5he2ZpbGw6IzRlNTA1NTt9PC9zdHlsZT48L2RlZnM+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTIgLTIpIj48cGF0aCBjbGFzcz0iYSIgZD0iTTIuNywxNkgxNS4zYS43LjcsMCwwLDAsLjctLjdWMy40YS43LjcsMCwwLDAtLjctLjdIMTIuNVYySDExLjF2LjdINi45VjJINS41di43SDIuN2EuNy43LDAsMCwwLS43LjdWMTUuM0EuNy43LDAsMCwwLDIuNywxNlptLjctMS40di03SDE0LjZ2N1pNNS41LDQuMXYuN0g2LjlWNC4xaDQuMnYuN2gxLjRWNC4xaDIuMVY2LjJIMy40VjQuMVoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgMCkiLz48cGF0aCBjbGFzcz0iYSIgZD0iTTcsMTJIOC40djEuNEg3Wm0wLDIuOEg4LjR2MS40SDdaTTkuOCwxMmgxLjR2MS40SDkuOFptMCwyLjhoMS40djEuNEg5LjhaTTEyLjYsMTJIMTR2MS40SDEyLjZabTAsMi44SDE0djEuNEgxMi42WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTEuNSAtMykiLz48L2c+PC9zdmc+"); 
    
    &.-red{
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNCAxNCI+PGRlZnM+PHN0eWxlPi5he2ZpbGw6I2M0MWUzZDt9PC9zdHlsZT48L2RlZnM+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTIgLTIpIj48cGF0aCBjbGFzcz0iYSIgZD0iTTIuNywxNkgxNS4zYS43LjcsMCwwLDAsLjctLjdWMy40YS43LjcsMCwwLDAtLjctLjdIMTIuNVYySDExLjF2LjdINi45VjJINS41di43SDIuN2EuNy43LDAsMCwwLS43LjdWMTUuM0EuNy43LDAsMCwwLDIuNywxNlptLjctMS40di03SDE0LjZ2N1pNNS41LDQuMXYuN0g2LjlWNC4xaDQuMnYuN2gxLjRWNC4xaDIuMVY2LjJIMy40VjQuMVoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgMCkiLz48cGF0aCBjbGFzcz0iYSIgZD0iTTcsMTJIOC40djEuNEg3Wm0wLDIuOEg4LjR2MS40SDdaTTkuOCwxMmgxLjR2MS40SDkuOFptMCwyLjhoMS40djEuNEg5LjhaTTEyLjYsMTJIMTR2MS40SDEyLjZabTAsMi44SDE0djEuNEgxMi42WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTEuNSAtMykiLz48L2c+PC9zdmc+"); 
    }
}

.a-icon.-logOut{
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMS42NjcgMTQiPjxkZWZzPjxzdHlsZT4uYXtmaWxsOiM0ZTUwNTU7fTwvc3R5bGU+PC9kZWZzPjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKC00IC0zKSI+PHBhdGggY2xhc3M9ImEiIGQ9Ik0xNi41NTYsMTEuMTExLDEzLjQ0NCw4djIuMzMzSDh2MS41NTZoNS40NDR2Mi4zMzNaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMC44ODkgLTEuMTExKSIvPjxwYXRoIGNsYXNzPSJhIiBkPSJNNCwzLjc3OFYxNi4yMjJBLjc3OC43NzgsMCwwLDAsNC43NzgsMTdoNS40NDRWMTUuNDQ0SDUuNTU2VjQuNTU2aDQuNjY3VjNINC43NzhBLjc3OC43NzgsMCwwLDAsNCwzLjc3OFoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDApIi8+PC9nPjwvc3ZnPg=="); 
    
    &.-red{
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMS42NjcgMTQiPjxkZWZzPjxzdHlsZT4uYXtmaWxsOiNjNDFlM2Q7fTwvc3R5bGU+PC9kZWZzPjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKC00IC0zKSI+PHBhdGggY2xhc3M9ImEiIGQ9Ik0xNi41NTYsMTEuMTExLDEzLjQ0NCw4djIuMzMzSDh2MS41NTZoNS40NDR2Mi4zMzNaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMC44ODkgLTEuMTExKSIvPjxwYXRoIGNsYXNzPSJhIiBkPSJNNCwzLjc3OFYxNi4yMjJBLjc3OC43NzgsMCwwLDAsNC43NzgsMTdoNS40NDRWMTUuNDQ0SDUuNTU2VjQuNTU2aDQuNjY3VjNINC43NzhBLjc3OC43NzgsMCwwLDAsNCwzLjc3OFoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDApIi8+PC9nPjwvc3ZnPg=="); 
    }
}

.a-icon.-crossCircle{
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMCAyMCI+PGRlZnM+PHN0eWxlPi5he2ZpbGw6IzI0MjcyZDt9PC9zdHlsZT48L2RlZnM+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTIgLTIpIj48cGF0aCBjbGFzcz0iYSIgZD0iTTE0Ljc5Myw3Ljc5MywxMiwxMC41ODYsOS4yMDcsNy43OTMsNy43OTMsOS4yMDcsMTAuNTg2LDEyLDcuNzkzLDE0Ljc5M2wxLjQxNCwxLjQxNEwxMiwxMy40MTRsMi43OTMsMi43OTMsMS40MTQtMS40MTRMMTMuNDE0LDEybDIuNzkzLTIuNzkzWiIvPjxwYXRoIGNsYXNzPSJhIiBkPSJNMTIsMkExMCwxMCwwLDEsMCwyMiwxMiwxMC4wMTEsMTAuMDExLDAsMCwwLDEyLDJabTAsMThhOCw4LDAsMSwxLDgtOEE4LjAwOSw4LjAwOSwwLDAsMSwxMiwyMFoiLz48L2c+PC9zdmc+"); 
    
    &.-red{
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMCAyMCI+PGRlZnM+PHN0eWxlPi5he2ZpbGw6I2M0MWUzZDt9PC9zdHlsZT48L2RlZnM+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTIgLTIpIj48cGF0aCBjbGFzcz0iYSIgZD0iTTE0Ljc5Myw3Ljc5MywxMiwxMC41ODYsOS4yMDcsNy43OTMsNy43OTMsOS4yMDcsMTAuNTg2LDEyLDcuNzkzLDE0Ljc5M2wxLjQxNCwxLjQxNEwxMiwxMy40MTRsMi43OTMsMi43OTMsMS40MTQtMS40MTRMMTMuNDE0LDEybDIuNzkzLTIuNzkzWiIvPjxwYXRoIGNsYXNzPSJhIiBkPSJNMTIsMkExMCwxMCwwLDEsMCwyMiwxMiwxMC4wMTEsMTAuMDExLDAsMCwwLDEyLDJabTAsMThhOCw4LDAsMSwxLDgtOEE4LjAwOSw4LjAwOSwwLDAsMSwxMiwyMFoiLz48L2c+PC9zdmc+"); 
    }
}

.a-icon.-cross{
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMy40MTQgMTMuNDE0Ij48ZGVmcz48c3R5bGU+LmF7ZmlsbDojMjQyNzJkO308L3N0eWxlPjwvZGVmcz48cGF0aCBjbGFzcz0iYSIgZD0iTTYuNzA3LDE4LjcwNywxMiwxMy40MTRsNS4yOTMsNS4yOTMsMS40MTQtMS40MTRMMTMuNDE0LDEybDUuMjkzLTUuMjkzTDE3LjI5Myw1LjI5MywxMiwxMC41ODYsNi43MDcsNS4yOTMsNS4yOTMsNi43MDcsMTAuNTg2LDEyLDUuMjkzLDE3LjI5M1oiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC01LjI5MyAtNS4yOTMpIi8+PC9zdmc+"); 
    
    &.-white{
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMy40MTQgMTMuNDE0Ij48ZGVmcz48c3R5bGU+LmF7ZmlsbDojZmZmO308L3N0eWxlPjwvZGVmcz48cGF0aCBjbGFzcz0iYSIgZD0iTTYuNzA3LDE4LjcwNywxMiwxMy40MTRsNS4yOTMsNS4yOTMsMS40MTQtMS40MTRMMTMuNDE0LDEybDUuMjkzLTUuMjkzTDE3LjI5Myw1LjI5MywxMiwxMC41ODYsNi43MDcsNS4yOTMsNS4yOTMsNi43MDcsMTAuNTg2LDEyLDUuMjkzLDE3LjI5M1oiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC01LjI5MyAtNS4yOTMpIi8+PC9zdmc+"); 
    }
    &.-red{
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMy40MTQgMTMuNDE0Ij48ZGVmcz48c3R5bGU+LmF7ZmlsbDojYzQxZTNkO308L3N0eWxlPjwvZGVmcz48cGF0aCBjbGFzcz0iYSIgZD0iTTYuNzA3LDE4LjcwNywxMiwxMy40MTRsNS4yOTMsNS4yOTMsMS40MTQtMS40MTRMMTMuNDE0LDEybDUuMjkzLTUuMjkzTDE3LjI5Myw1LjI5MywxMiwxMC41ODYsNi43MDcsNS4yOTMsNS4yOTMsNi43MDcsMTAuNTg2LDEyLDUuMjkzLDE3LjI5M1oiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC01LjI5MyAtNS4yOTMpIi8+PC9zdmc+"); 
    }
}

.a-icon.-store{
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMCAzMCI+PGRlZnM+PHN0eWxlPi5he2ZpbGw6IzI0MjcyZDt9PC9zdHlsZT48L2RlZnM+PHBhdGggY2xhc3M9ImEiIGQ9Ik0zMiwxMWExLjQ5MiwxLjQ5MiwwLDAsMC0uMjE1LS43NzJsLTQuNS03LjVBMS41LDEuNSwwLDAsMCwyNiwySDhhMS41LDEuNSwwLDAsMC0xLjI4NS43MjdsLTQuNSw3LjVBMS40OTIsMS40OTIsMCwwLDAsMiwxMWE1Ljk1NCw1Ljk1NCwwLDAsMCwxLjUsMy45MjdWMzAuNUExLjUsMS41LDAsMCwwLDUsMzJIMjlhMS41LDEuNSwwLDAsMCwxLjUtMS41VjE0LjkyN0E1Ljk1NCw1Ljk1NCwwLDAsMCwzMiwxMVptLTMuMDI0LjM3N0EzLDMsMCwwLDEsMjMsMTFhMS4zMjYsMS4zMjYsMCwwLDAtLjA1OS0uMjg4bC4wMy0uMDA2TDIxLjgzLDVoMy4zMjFaTTE1LjIyOCw1SDE4Ljc3bDEuMjE5LDYuMWEyLjk5MSwyLjk5MSwwLDAsMS01Ljk3OSwwWk04Ljg0OSw1SDEyLjE3bC0xLjE0LDUuNzA2LjAzLjAwNkExLjI0NSwxLjI0NSwwLDAsMCwxMSwxMWEzLDMsMCwwLDEtNS45NzYuMzc3Wk0xNCwyOVYyM2g2djZabTksMFYyMS41QTEuNSwxLjUsMCwwLDAsMjEuNSwyMGgtOUExLjUsMS41LDAsMCwwLDExLDIxLjVWMjlINi41VjE2Ljc4N2E1Ljg0OCw1Ljg0OCwwLDAsMCw2LTEuODIzLDUuOTkyLDUuOTkyLDAsMCwwLDksMCw1Ljg0OCw1Ljg0OCwwLDAsMCw2LDEuODIzVjI5WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTIgLTIpIi8+PC9zdmc+"); 
    
    &.-white{
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMCAzMCI+PGRlZnM+PHN0eWxlPi5he2ZpbGw6I2ZmZjt9PC9zdHlsZT48L2RlZnM+PHBhdGggY2xhc3M9ImEiIGQ9Ik0zMiwxMWExLjQ5MiwxLjQ5MiwwLDAsMC0uMjE1LS43NzJsLTQuNS03LjVBMS41LDEuNSwwLDAsMCwyNiwySDhhMS41LDEuNSwwLDAsMC0xLjI4NS43MjdsLTQuNSw3LjVBMS40OTIsMS40OTIsMCwwLDAsMiwxMWE1Ljk1NCw1Ljk1NCwwLDAsMCwxLjUsMy45MjdWMzAuNUExLjUsMS41LDAsMCwwLDUsMzJIMjlhMS41LDEuNSwwLDAsMCwxLjUtMS41VjE0LjkyN0E1Ljk1NCw1Ljk1NCwwLDAsMCwzMiwxMVptLTMuMDI0LjM3N0EzLDMsMCwwLDEsMjMsMTFhMS4zMjYsMS4zMjYsMCwwLDAtLjA1OS0uMjg4bC4wMy0uMDA2TDIxLjgzLDVoMy4zMjFaTTE1LjIyOCw1SDE4Ljc3bDEuMjE5LDYuMWEyLjk5MSwyLjk5MSwwLDAsMS01Ljk3OSwwWk04Ljg0OSw1SDEyLjE3bC0xLjE0LDUuNzA2LjAzLjAwNkExLjI0NSwxLjI0NSwwLDAsMCwxMSwxMWEzLDMsMCwwLDEtNS45NzYuMzc3Wk0xNCwyOVYyM2g2djZabTksMFYyMS41QTEuNSwxLjUsMCwwLDAsMjEuNSwyMGgtOUExLjUsMS41LDAsMCwwLDExLDIxLjVWMjlINi41VjE2Ljc4N2E1Ljg0OCw1Ljg0OCwwLDAsMCw2LTEuODIzLDUuOTkyLDUuOTkyLDAsMCwwLDksMCw1Ljg0OCw1Ljg0OCwwLDAsMCw2LDEuODIzVjI5WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTIgLTIpIi8+PC9zdmc+"); 
    }
}

.a-icon.-car{
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMCAyOC41Ij48ZGVmcz48c3R5bGU+LmF7ZmlsbDojNGU1MDU1O308L3N0eWxlPjwvZGVmcz48cGF0aCBjbGFzcz0iYSIgZD0iTTI4Ljg5NCw4LjQ0M0ExLjUsMS41LDAsMCwwLDI3LjUsNy41aC02di0zQTEuNSwxLjUsMCwwLDAsMjAsM0gzLjVBMS41LDEuNSwwLDAsMCwyLDQuNVYyNGExLjUsMS41LDAsMCwwLDEuNSwxLjVINS4wNzZBNC45NjQsNC45NjQsMCwwLDAsNSwyNi4yNWE1LjI1LDUuMjUsMCwwLDAsMTAuNSwwLDQuOTY0LDQuOTY0LDAsMCwwLS4wNzYtLjc1aDMuMTUzYTQuOTY0LDQuOTY0LDAsMCwwLS4wNzYuNzUsNS4yNSw1LjI1LDAsMCwwLDEwLjUsMCw0Ljk2NCw0Ljk2NCwwLDAsMC0uMDc3LS43NUgzMC41QTEuNSwxLjUsMCwwLDAsMzIsMjRWMTYuNWExLjUsMS41LDAsMCwwLS4xMDYtLjU1NmwtMy03LjVaTTIxLjUsMTAuNWg0Ljk4NGwxLjgsNC41SDIxLjVabS0xMS4yNSwxOGEyLjI1LDIuMjUsMCwxLDEsMi4yNS0yLjI1QTIuMjUzLDIuMjUzLDAsMCwxLDEwLjI1LDI4LjVabTguMjUtNkgxMy45MTFhNS4yMiw1LjIyLDAsMCwwLTcuNDExLjA4OFYyMi41SDVWNkgxOC41Wm01LjI1LDZBMi4yNSwyLjI1LDAsMSwxLDI2LDI2LjI1LDIuMjUzLDIuMjUzLDAsMCwxLDIzLjc1LDI4LjVabTMuNjYxLTZBNS4yMjksNS4yMjksMCwwLDAsMjMuNzUsMjFhNS4xNzcsNS4xNzcsMCwwLDAtMi4yNS41MjhWMThIMjl2NC41WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTIgLTMpIi8+PC9zdmc+"); 
    
    &.-white{
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMCAyOC41Ij48ZGVmcz48c3R5bGU+LmF7ZmlsbDojZmZmO308L3N0eWxlPjwvZGVmcz48cGF0aCBjbGFzcz0iYSIgZD0iTTI4Ljg5NCw4LjQ0M0ExLjUsMS41LDAsMCwwLDI3LjUsNy41aC02di0zQTEuNSwxLjUsMCwwLDAsMjAsM0gzLjVBMS41LDEuNSwwLDAsMCwyLDQuNVYyNGExLjUsMS41LDAsMCwwLDEuNSwxLjVINS4wNzZBNC45NjQsNC45NjQsMCwwLDAsNSwyNi4yNWE1LjI1LDUuMjUsMCwwLDAsMTAuNSwwLDQuOTY0LDQuOTY0LDAsMCwwLS4wNzYtLjc1aDMuMTUzYTQuOTY0LDQuOTY0LDAsMCwwLS4wNzYuNzUsNS4yNSw1LjI1LDAsMCwwLDEwLjUsMCw0Ljk2NCw0Ljk2NCwwLDAsMC0uMDc3LS43NUgzMC41QTEuNSwxLjUsMCwwLDAsMzIsMjRWMTYuNWExLjUsMS41LDAsMCwwLS4xMDYtLjU1NmwtMy03LjVaTTIxLjUsMTAuNWg0Ljk4NGwxLjgsNC41SDIxLjVabS0xMS4yNSwxOGEyLjI1LDIuMjUsMCwxLDEsMi4yNS0yLjI1QTIuMjUzLDIuMjUzLDAsMCwxLDEwLjI1LDI4LjVabTguMjUtNkgxMy45MTFhNS4yMiw1LjIyLDAsMCwwLTcuNDExLjA4OFYyMi41SDVWNkgxOC41Wm01LjI1LDZBMi4yNSwyLjI1LDAsMSwxLDI2LDI2LjI1LDIuMjUzLDIuMjUzLDAsMCwxLDIzLjc1LDI4LjVabTMuNjYxLTZBNS4yMjksNS4yMjksMCwwLDAsMjMuNzUsMjFhNS4xNzcsNS4xNzcsMCwwLDAtMi4yNS41MjhWMThIMjl2NC41WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTIgLTMpIi8+PC9zdmc+"); 
    }
}

.a-icon.-card{
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMCAyNCI+PGRlZnM+PHN0eWxlPi5he2ZpbGw6IzRlNTA1NTt9PC9zdHlsZT48L2RlZnM+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTIgLTQpIj48cGF0aCBjbGFzcz0iYSIgZD0iTTMwLjUsNEgzLjVBMS41LDEuNSwwLDAsMCwyLDUuNXYyMUExLjUsMS41LDAsMCwwLDMuNSwyOGgyN0ExLjUsMS41LDAsMCwwLDMyLDI2LjVWNS41QTEuNSwxLjUsMCwwLDAsMzAuNSw0Wk0yOSw3djNINVY3Wk01LDI1VjE2SDI5djlaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwKSIvPjxwYXRoIGNsYXNzPSJhIiBkPSJNNiwxNGg5djNINloiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDIgNSkiLz48L2c+PC9zdmc+"); 
    
    &.-white{
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMCAyNCI+PGRlZnM+PHN0eWxlPi5he2ZpbGw6I2ZmZjt9PC9zdHlsZT48L2RlZnM+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTIgLTQpIj48cGF0aCBjbGFzcz0iYSIgZD0iTTMwLjUsNEgzLjVBMS41LDEuNSwwLDAsMCwyLDUuNXYyMUExLjUsMS41LDAsMCwwLDMuNSwyOGgyN0ExLjUsMS41LDAsMCwwLDMyLDI2LjVWNS41QTEuNSwxLjUsMCwwLDAsMzAuNSw0Wk0yOSw3djNINVY3Wk01LDI1VjE2SDI5djlaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwKSIvPjxwYXRoIGNsYXNzPSJhIiBkPSJNNiwxNGg5djNINloiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDIgNSkiLz48L2c+PC9zdmc+"); 
    }
}

.a-icon.-box{
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMCAyOS45ODUiPjxkZWZzPjxzdHlsZT4uYXtmaWxsOiM0ZTUwNTU7fTwvc3R5bGU+PC9kZWZzPjxwYXRoIGNsYXNzPSJhIiBkPSJNMzEuOTg5LDEwLjkxM2ExLjY2NCwxLjY2NCwwLDAsMC0uMDQzLS4zMTIsMS41MTcsMS41MTcsMCwwLDAtLjA2My0uMTU0LDEuNTQ0LDEuNTQ0LDAsMCwwLS4wODctLjIsMS41LDEuNSwwLDAsMC0uMTItLjE1OCwxLjgzNiwxLjgzNiwwLDAsMC0uMTE3LS4xNSwxLjQ0NiwxLjQ0NiwwLDAsMC0uMTYtLjEyNiwxLjY0NSwxLjY0NSwwLDAsMC0uMTM5LS4xMDhsLS4wMTUtLjAwNi0uMDE4LS4wMTNMMTcuNjk0LDIuMmExLjUsMS41LDAsMCwwLTEuNDU1LDBMMi43NzEsOS42ODNhLjAyMS4wMjEsMCwwLDEtLjAxMi4wMWMtLjAwNiwwLS4wMTQsMC0uMDE5LjAwOWExLjM4MSwxLjM4MSwwLDAsMC0uMTguMTQxYy0uMDM4LjAzLS4wODMuMDU3LS4xMTcuMDlhMS42MjMsMS42MjMsMCwwLDAtLjE1My4yYy0uMDI3LjAzOS0uMDYxLjA3Mi0uMDg0LjExMmExLjQ0MywxLjQ0MywwLDAsMC0uMS4yNDhjLS4wMTQuMDM3LS4wMzUuMDczLS4wNDUuMTExYTEuMzcsMS4zNywwLDAsMC0uMDQ1LjMyMWMwLC4wMjYtLjAxMS4wNDctLjAxMS4wNzJWMjNhMS41LDEuNSwwLDAsMCwuNzczLDEuMzExbDEzLjQ2Nyw3LjQ4aDBsLjAzLjAxN2ExLjQ2MiwxLjQ2MiwwLDAsMCwuMi4wODEsMS4yMjcsMS4yMjcsMCwwLDAsLjE0Ni4wNTgsMS41MTksMS41MTksMCwwLDAsLjc1OSwwLDEuNDczLDEuNDczLDAsMCwwLC4xNDYtLjA1OCwxLjMsMS4zLDAsMCwwLC4yLS4wODFsLjAzLS4wMTdoMGwxMy40NjctNy40OEExLjUsMS41LDAsMCwwLDMyLDIzdi0xMmMwLS4wMjktLjAwOS0uMDUyLS4wMTEtLjA4Wk0xNy4wNDIsMTYuOGwtNC4yODEtMi4zNzlMMjMuMTY4LDguNjUyLDI3LjQxMywxMVpNMTYuOTcsNS4yMjUsMTkuNyw2LjczNSw5LjM3NywxMi41NDUsNi41ODgsMTFaTTUsMTMuNTU0bDMsMS42Nzl2My4xMTJMMTEsMjBWMTYuOTExbDQuNSwyLjUxNywwLDguNTE4TDUsMjIuMTEyWk0xOC41LDI3Ljk0N3YtOC41MkwyOSwxMy41NTN2OC41NTlMMTguNSwyNy45NDdaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMiAtMi4wMDkpIi8+PC9zdmc+"); 
    
    &.-white{
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMCAyOS45ODUiPjxkZWZzPjxzdHlsZT4uYXtmaWxsOiNmZmY7fTwvc3R5bGU+PC9kZWZzPjxwYXRoIGNsYXNzPSJhIiBkPSJNMzEuOTg5LDEwLjkxM2ExLjY2NCwxLjY2NCwwLDAsMC0uMDQzLS4zMTIsMS41MTcsMS41MTcsMCwwLDAtLjA2My0uMTU0LDEuNTQ0LDEuNTQ0LDAsMCwwLS4wODctLjIsMS41LDEuNSwwLDAsMC0uMTItLjE1OCwxLjgzNiwxLjgzNiwwLDAsMC0uMTE3LS4xNSwxLjQ0NiwxLjQ0NiwwLDAsMC0uMTYtLjEyNiwxLjY0NSwxLjY0NSwwLDAsMC0uMTM5LS4xMDhsLS4wMTUtLjAwNi0uMDE4LS4wMTNMMTcuNjk0LDIuMmExLjUsMS41LDAsMCwwLTEuNDU1LDBMMi43NzEsOS42ODNhLjAyMS4wMjEsMCwwLDEtLjAxMi4wMWMtLjAwNiwwLS4wMTQsMC0uMDE5LjAwOWExLjM4MSwxLjM4MSwwLDAsMC0uMTguMTQxYy0uMDM4LjAzLS4wODMuMDU3LS4xMTcuMDlhMS42MjMsMS42MjMsMCwwLDAtLjE1My4yYy0uMDI3LjAzOS0uMDYxLjA3Mi0uMDg0LjExMmExLjQ0MywxLjQ0MywwLDAsMC0uMS4yNDhjLS4wMTQuMDM3LS4wMzUuMDczLS4wNDUuMTExYTEuMzcsMS4zNywwLDAsMC0uMDQ1LjMyMWMwLC4wMjYtLjAxMS4wNDctLjAxMS4wNzJWMjNhMS41LDEuNSwwLDAsMCwuNzczLDEuMzExbDEzLjQ2Nyw3LjQ4aDBsLjAzLjAxN2ExLjQ2MiwxLjQ2MiwwLDAsMCwuMi4wODEsMS4yMjcsMS4yMjcsMCwwLDAsLjE0Ni4wNTgsMS41MTksMS41MTksMCwwLDAsLjc1OSwwLDEuNDczLDEuNDczLDAsMCwwLC4xNDYtLjA1OCwxLjMsMS4zLDAsMCwwLC4yLS4wODFsLjAzLS4wMTdoMGwxMy40NjctNy40OEExLjUsMS41LDAsMCwwLDMyLDIzdi0xMmMwLS4wMjktLjAwOS0uMDUyLS4wMTEtLjA4Wk0xNy4wNDIsMTYuOGwtNC4yODEtMi4zNzlMMjMuMTY4LDguNjUyLDI3LjQxMywxMVpNMTYuOTcsNS4yMjUsMTkuNyw2LjczNSw5LjM3NywxMi41NDUsNi41ODgsMTFaTTUsMTMuNTU0bDMsMS42Nzl2My4xMTJMMTEsMjBWMTYuOTExbDQuNSwyLjUxNywwLDguNTE4TDUsMjIuMTEyWk0xOC41LDI3Ljk0N3YtOC41MkwyOSwxMy41NTN2OC41NTlMMTguNSwyNy45NDdaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMiAtMi4wMDkpIi8+PC9zdmc+"); 
    }
}

.a-icon.-wallet{
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMCAyNS41Ij48ZGVmcz48c3R5bGU+LmF7ZmlsbDojNGU1MDU1O308L3N0eWxlPjwvZGVmcz48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMiAtMykiPjxwYXRoIGNsYXNzPSJhIiBkPSJNMTYsMTJoM3Y0LjVIMTZaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg3IDQuNSkiLz48cGF0aCBjbGFzcz0iYSIgZD0iTTMwLjUsOUgyOVY0LjVBMS41LDEuNSwwLDAsMCwyNy41LDNINi41QTQuMzc0LDQuMzc0LDAsMCwwLDIsNy41VjI0YTQuMzc0LDQuMzc0LDAsMCwwLDQuNSw0LjVoMjRBMS41LDEuNSwwLDAsMCwzMiwyN1YxMC41QTEuNSwxLjUsMCwwLDAsMzAuNSw5Wk02LjUsNkgyNlY5SDYuNTE4QTEuMzc4LDEuMzc4LDAsMCwxLDUsNy41LDEuMzczLDEuMzczLDAsMCwxLDYuNSw2Wk0yOSwyNS41SDYuNTE4QTEuMzc4LDEuMzc4LDAsMCwxLDUsMjRWMTEuNzVBNC44MSw0LjgxLDAsMCwwLDYuNSwxMkgyOVoiLz48L2c+PC9zdmc+"); 
    
    &.-white{
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMCAyNS41Ij48ZGVmcz48c3R5bGU+LmF7ZmlsbDojZmZmO308L3N0eWxlPjwvZGVmcz48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMiAtMykiPjxwYXRoIGNsYXNzPSJhIiBkPSJNMTYsMTJoM3Y0LjVIMTZaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg3IDQuNSkiLz48cGF0aCBjbGFzcz0iYSIgZD0iTTMwLjUsOUgyOVY0LjVBMS41LDEuNSwwLDAsMCwyNy41LDNINi41QTQuMzc0LDQuMzc0LDAsMCwwLDIsNy41VjI0YTQuMzc0LDQuMzc0LDAsMCwwLDQuNSw0LjVoMjRBMS41LDEuNSwwLDAsMCwzMiwyN1YxMC41QTEuNSwxLjUsMCwwLDAsMzAuNSw5Wk02LjUsNkgyNlY5SDYuNTE4QTEuMzc4LDEuMzc4LDAsMCwxLDUsNy41LDEuMzczLDEuMzczLDAsMCwxLDYuNSw2Wk0yOSwyNS41SDYuNTE4QTEuMzc4LDEuMzc4LDAsMCwxLDUsMjRWMTEuNzVBNC44MSw0LjgxLDAsMCwwLDYuNSwxMkgyOVoiLz48L2c+PC9zdmc+"); 
    }
}

.a-icon.-dots{
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTIgMTBjLTEuMSAwLTIgLjktMiAycy45IDIgMiAyIDItLjkgMi0yLS45LTItMi0yek0xOCAxMGMtMS4xIDAtMiAuOS0yIDJzLjkgMiAyIDIgMi0uOSAyLTItLjktMi0yLTJ6TTYgMTBjLTEuMSAwLTIgLjktMiAycy45IDIgMiAyIDItLjkgMi0yLS45LTItMi0yeiIvPjwvc3ZnPg=="); 
    
    &.-white{
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNiA0Ij4NCiAgPGRlZnM+DQogICAgPHN0eWxlPg0KICAgICAgLmNscy0xIHsNCiAgICAgICAgZmlsbDogI2ZmZjsNCiAgICAgIH0NCiAgICA8L3N0eWxlPg0KICA8L2RlZnM+DQogIDxwYXRoIGlkPSJieC1kb3RzLWhvcml6b250YWwtcm91bmRlZCIgY2xhc3M9ImNscy0xIiBkPSJNMTIsMTBhMiwyLDAsMSwwLDIsMkEyLjAwNiwyLjAwNiwwLDAsMCwxMiwxMFptNiwwYTIsMiwwLDEsMCwyLDJBMi4wMDYsMi4wMDYsMCwwLDAsMTgsMTBaTTYsMTBhMiwyLDAsMSwwLDIsMkEyLjAwNiwyLjAwNiwwLDAsMCw2LDEwWiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTQgLTEwKSIvPg0KPC9zdmc+DQo="); 
    }
}

.a-icon.-info{
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMCAyMCI+PGRlZnM+PHN0eWxlPi5he2ZpbGw6IzRlNTA1NTt9PC9zdHlsZT48L2RlZnM+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTIgLTIpIj48cGF0aCBjbGFzcz0iYSIgZD0iTTEyLDJBMTAsMTAsMCwxLDAsMjIsMTIsMTAuMDExLDEwLjAxMSwwLDAsMCwxMiwyWm0wLDE4YTgsOCwwLDEsMSw4LThBOC4wMDksOC4wMDksMCwwLDEsMTIsMjBaIi8+PHBhdGggY2xhc3M9ImEiIGQ9Ik0xMSwxMWgydjZIMTFabTAtNGgyVjlIMTFaIi8+PC9nPjwvc3ZnPg=="); 
}



.a-icon.-facebook{
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxOCAxOCI+PGRlZnM+PHN0eWxlPi5he2ZpbGw6IzNiNTk5ODt9PC9zdHlsZT48L2RlZnM+PHBhdGggY2xhc3M9ImEiIGQ9Ik0yMCwzSDRBMSwxLDAsMCwwLDMsNFYyMGExLDEsMCwwLDAsMSwxaDguNjE1VjE0LjA0SDEwLjI3N1YxMS4zMTVoMi4zMzh2LTJhMy4yNzYsMy4yNzYsMCwwLDEsMy41LTMuNTkyLDE5LjU1NywxOS41NTcsMCwwLDEsMi4wOTUuMTA3VjguMjVIMTYuNzc1Yy0xLjEyOCwwLTEuMzQ4LjUzOC0xLjM0OCwxLjMyNVYxMS4zMWgyLjdsLS4zNSwyLjcyNUgxNS40MjZWMjFIMjBhMSwxLDAsMCwwLDEtMVY0QTEsMSwwLDAsMCwyMCwzWiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTMgLTMpIi8+PC9zdmc+"); 
}

.a-icon.-twitter{
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxOCAxOCI+PGRlZnM+PHN0eWxlPi5he2ZpbGw6IzQ5YWFlYjt9PC9zdHlsZT48L2RlZnM+PHBhdGggY2xhc3M9ImEiIGQ9Ik0xOC43NSwyMUg1LjI1QTIuMjUxLDIuMjUxLDAsMCwxLDMsMTguNzVWNS4yNUEyLjI1MSwyLjI1MSwwLDAsMSw1LjI1LDNoMTMuNUEyLjI1MSwyLjI1MSwwLDAsMSwyMSw1LjI1djEzLjVBMi4yNTEsMi4yNTEsMCwwLDEsMTguNzUsMjFaTTkuOTEyLDE2LjU3MmE2LjUyNCw2LjUyNCwwLDAsMCw2LjU2Ni02LjU2NWMwLS4xLDAtLjItLjAwNi0uM2E0LjY4NSw0LjY4NSwwLDAsMCwxLjE1Mi0xLjIsNC42NzQsNC42NzQsMCwwLDEtMS4zMjcuMzY1QTIuMzI0LDIuMzI0LDAsMCwwLDE3LjMxNCw3LjZhNC41ODMsNC41ODMsMCwwLDEtMS40NjcuNTU5LDIuMzA5LDIuMzA5LDAsMCwwLTMuOTkzLDEuNTgsMi40OCwyLjQ4LDAsMCwwLC4wNi41MjZBNi41NTEsNi41NTEsMCwwLDEsNy4xNTcsNy44NTJhMi4zMTksMi4zMTksMCwwLDAsLjcxNiwzLjA4MSwyLjM0NiwyLjM0NiwwLDAsMS0xLjA0NC0uMjg4di4wMzJBMi4zMTIsMi4zMTIsMCwwLDAsOC42OCwxMi45NGEyLjI1MywyLjI1MywwLDAsMS0uNjA4LjA4MSwyLjIsMi4yLDAsMCwxLS40MzItLjA0MSwyLjMwNywyLjMwNywwLDAsMCwyLjE1NSwxLjYsNC42MjksNC42MjksMCwwLDEtMi44NjYuOTksNC41NjMsNC41NjMsMCwwLDEtLjU1LS4wMzEsNi41NjUsNi41NjUsMCwwLDAsMy41MzMsMS4wMzEiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0zIC0zKSIvPjwvc3ZnPg=="); 
}

.a-icon.-googlePlus{
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxOC4wMDEgMTgiPjxkZWZzPjxzdHlsZT4uYXtmaWxsOiNjNDFlM2Q7fTwvc3R5bGU+PC9kZWZzPjxwYXRoIGNsYXNzPSJhIiBkPSJNMjAsMTAuOTdhOSw5LDAsMCwwLTE4LDBjMC0uMDA5LDAtLjAxNSwwLS4wMjNWMTFIMnYuMDQ5YS4xNjYuMTY2LDAsMCwxLDAtLjAyMSw5LDksMCwwLDAsMTgsMGMwLC4wMDgsMCwuMDE1LDAsLjAyM3YtLjEwNkMyMCwxMC45NTYsMjAsMTAuOTYyLDIwLDEwLjk3Wk04LjU0OCwxNS4wOTJhNC4wOTEsNC4wOTEsMCwwLDEsMC04LjE4MiwzLjkyMSwzLjkyMSwwLDAsMSwyLjczOSwxLjA2OEwxMC4xMjMsOS4xMDdBMi4yNDIsMi4yNDIsMCwwLDAsOC41NDgsOC41YTIuNSwyLjUsMCwwLDAsMCw1LjAwOSwyLjEyLDIuMTIsMCwwLDAsMi4yNS0xLjcyN0g4LjU0N1YxMC4yNTZoMy44MjdhMy40NDIsMy40NDIsMCwwLDEsLjEuODM4QTMuNzc1LDMuNzc1LDAsMCwxLDguNTQ4LDE1LjA5MlptOS40MDYtMy42ODNIMTYuMzE4djEuNjM2SDE1LjA5MVYxMS40MDlIMTMuNDU0VjEwLjE4MmgxLjYzN1Y4LjU0NmgxLjIyN3YxLjYzN2gxLjYzNloiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0yIC0yKSIvPjwvc3ZnPg=="); 
}
