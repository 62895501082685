/* ========================================================================== */
/* BUTTONS */
/* ========================================================================== */

.btn{
    @include f-16-white-medium;
    min-height: 5.4rem;
    height: 100%;
    padding: 0rem 2.5rem 0rem 2.5rem;
    background: $bg-brand;
    border-radius: $border-radius-primary;
    position: relative;
    border: 0;
    cursor: pointer;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 1;
    white-space: normal;

    .a-btnIcon{
        background: rgba(0,0,0,0.1);
        min-height: 5.4rem;
        height: 100%;
        width: 5.4rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-left: 2.5rem;
        margin-right: -2.5rem;
        flex-shrink: 0;
    }
    .a-icon{
        display: inline-block;
        height: 12px;
        width: 12px;
    }
    &:hover, &:active, &:focus{
        color: $text-white;
        box-shadow: none;
        background: $brand-primary--dark;
    }
}
@media screen and (max-width: 600px){
    .btn{
        padding: 0 2rem;

        .a-btnIcon{
            margin-left: 2rem;
            margin-right: -2rem;
            width: 4rem;
        }
    }
}
@media screen and (max-width: 400px){
    .btn{
        padding: 0 1rem;

        .a-btnIcon{
            margin-left: 1rem;
            margin-right: -1rem;
            width: 3rem;
        }
    }
}
.btn.-fullWidth{
    width: 100%;
}
.btn.-small{
    min-height: 4.8rem;
    @include f-14-white-medium;

    .a-btnIcon{
        min-height: 4.8rem;
    }
}

.btn.-light{
    background: $bg-light;
    color: $text-primary;

    &:hover, &:active, &:focus {
        opacity: 0.8;
    }
}
.btn.-transparent{
    background: transparent;
    overflow: hidden;

    &::after{
        content: "";
        @include absolute-position(0,0,0,0);
        opacity: 0.15;
        transition: 0.3s ease all;
    }
    &:hover, &:active, &:focus {
        &::after{
            opacity: 0.25;  
        }
    }
}

/* ========================================================================== */
/* BTN COLOR VARIATIONS */
/* ========================================================================== */

.btn.-secondary{
    background: $bg-brand-secondary;


    &:hover, &:active, &:focus{
        background: $bg-brand-secondary-dark;
    }
    &.-transparent{
        background: transparent;
        color: $text-brand-secondary;

        &::after{
            background: $bg-brand-secondary;

        }
    }
}

.btn.-dark{
    background: $bg-dark;

    &.-transparent{
        background: transparent;
        color: $text-dark;

        &::after{
            background: $bg-dark;
        }
    }  
}

.btn.-remove{
    background: $bg-red;

    &.-transparent{
        background: transparent;
        color: $text-red;

        &::after{
            background: $bg-red;
        }
    }
}

/* IE 10, 11 CSS */ 
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none){
    .btn{
        height: 5.4rem;
    }
}