.o-cartNav{
    background: $bg-white;
    padding: 0;
}
.o-cartNav__container{
    display: flex;
    flex-direction: row;
}
.o-cartNav__single{
    background: $bg-white;
    position: relative;
    height: 100px;
    flex-basis: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    z-index: 1;
}
.o-cartNav__title{
    @include f-16-primary-medium;
    margin-bottom: 10px;
}
.o-cartNav__number{
    @include f-16-primary-medium;
    position: absolute;
    bottom: 0;
    left: 50%;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: $bg-white;
    border: 4px solid $border-light;
    transform: translateX(-50%) translateY(50%);
    z-index: 2;
}

.o-cartNav__single.-active{
    &::after{
        content: "";
        height: 4px;
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        background: $bg-brand;
    }
    .o-cartNav__title{
        color: $text-brand;
    }
    .o-cartNav__number{
        color: $text-white;
        background: $bg-brand;
    }
}
.o-cartNav__single.-complete{
    &::after{
        content: "";
        height: 4px;
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        background: $bg-brand-secondary;
    }
    .o-cartNav__title{
        color: $text-brand-secondary;
    }
    .o-cartNav__number{
        color: $text-white;
        background: $bg-brand-secondary;
    }
}



@media screen and (max-width: 991px){
    .o-cartNav{
        display: none;
    }
}