.m-cartUserItem{
    border-radius: $border-radius-primary;
    position: relative;
    height: 100%;
    background: $bg-white;

    &.-active .m-cartUserItem__top, &.-active .m-cartUserItem__iconWrap{
        background: $bg-brand;
    }
}
.m-cartUserItem__top{
    background: $bg-dark;
    padding-top: 5rem;
    border-top-left-radius: $border-radius-primary;
    border-top-right-radius: $border-radius-primary;
}
.m-cartUserItem__iconWrap{
    height: 80px;
    width: 80px;
    border-radius: 50%;
    border: 4px solid $border-white;
    background: $bg-dark;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateX(-50%) translateY(-50%);
    z-index: 2;
    position: absolute;
    top: 0;
    left: 50%;
}
.m-cartUserItem__icon{
    height: 30px;
    width: 30px;
}
.m-cartUserItem_title{
    @include f-18-white-medium;
    text-align: center;
    margin-bottom: 10px;
}
.m-cartUserItem_subtitleWrap{
    display: flex;
    flex-direction: row;
}
.m-cartUserItem_subtitle{
    @include f-14-white-regular;
    flex-grow: 1;
    text-align: center;
    padding: 15px 10px;
    border-top: 1px solid rgba(255,255,255,0.1);

    &:last-child{
        border-left: 1px solid rgba(255,255,255,0.1);
    }
}
.m-cartUserItem__bottom{
    background: $bg-white;
    padding: 20px;
    padding-bottom: 0;
    border-bottom-left-radius: $border-radius-primary;
    border-bottom-right-radius: $border-radius-primary;
}
.m-cartUserItem__street{
    padding-bottom: 20px;
}
.m-cartUserItem__small{
    @include f-12-dark-regular;
    line-height: 1;
    padding-bottom: 5px;
}
.m-cartUserItem__text{
    @include f-18-primary-medium;
    line-height: 1;
}
.m-cartUserItem__row{
    display: flex;
    flex-direction: row;
    padding-bottom: 20px;
}
.m-cartUserItem__city{
    flex-grow: 1;   
    padding-right: 10px;
    width: 50%;
}
.m-cartUserItem__psc{
    flex-grow: 1;   
    width: 50%;
    padding-right: 0;
}