.m-categoryCell{
    background: $bg-white;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: 0.3s ease all;
    margin: 10px 0;
    border-radius: $border-radius-primary;
    flex-wrap: wrap;

    &:hover, &:active, &:focus{
        text-decoration: none;
        box-shadow: $box-shadow-primary;
    }
}
.m-categoryCell__img{
    height: 60px;
}
.m-categoryCell__title{
    @include f-16-primary-medium;
    margin: 0;
    margin-left: 0;
    margin-top: 1rem;
    flex-grow: 1;
    text-align: center;
}